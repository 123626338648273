// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-restaurants-js": () => import("./../../../src/pages/restaurants.js" /* webpackChunkName: "component---src-pages-restaurants-js" */),
  "component---src-pages-spaces-js": () => import("./../../../src/pages/spaces.js" /* webpackChunkName: "component---src-pages-spaces-js" */),
  "component---src-templates-gallery-query-js": () => import("./../../../src/templates/gallery-query.js" /* webpackChunkName: "component---src-templates-gallery-query-js" */)
}

