export default [
    0,
    4,
    8,
    16,
    32,
    64,
    128,
    256,
    512
  ]