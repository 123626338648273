export default {
  links: {
    nav: {
      textDecoration: "none",
      color: "secondary",
      "&&:hover": {
        color: "muted"
      }
    }
  }
};
